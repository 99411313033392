import { z, type RefinementCtx } from 'zod'

import { convertFromTimezone } from './date'

type Falsy = false | null | undefined | 0 | ''

export const isNotFalsy = <T>(a: T | Falsy): a is T => Boolean(a)

export const onlyValidArraySchema = <T extends z.ZodSchema>(schema: T) =>
  z.array(schema.nullable().catch(null)).transform(x => x.filter(isNotFalsy))

export const dateSchema = z
  .date()
  .or(z.string().datetime({ offset: true }))
  .or(z.string().date())
  .transform(value => new Date(value))

export const localizedDateSchema = dateSchema.transform(d =>
  convertFromTimezone(d),
)

const isoDurationRegex =
  /^P(?!$)(\d+(?:\.\d+)?Y)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?W)?(\d+(?:\.\d+)?D)?(T(?=\d)(\d+(?:\.\d+)?H)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?S)?)?$/

export const durationSchema = z
  .string()
  .regex(isoDurationRegex)
  .brand<'IsoDuration'>()

export type IsoDuration = z.infer<typeof durationSchema>
export const isoDuration = (duration: string) => durationSchema.parse(duration)

const timeRegex = /^\d{2}:\d{2}$/
export const timeSchema = z.string().regex(timeRegex)

const stringOrStringArraySchema = z
  .array(z.string())
  .nonempty()
  .or(z.string().nonempty())

export const stringValueCheckboxArraySchema =
  stringOrStringArraySchema.transform(v => (Array.isArray(v) ? v : [v]))

export const numberValueCheckboxArraySchema =
  stringOrStringArraySchema.transform(v =>
    Array.isArray(v) ? v.map(Number) : [Number(v)],
  )

export const zodAlwaysRefine = <T extends z.ZodTypeAny>(zodType: T) =>
  z.any().superRefine(async (value, ctx) => {
    const res = await zodType.safeParseAsync(value)

    if (res.success === false)
      for (const issue of res.error.issues) {
        ctx.addIssue(issue)
      }
  }) as unknown as T

export const zodAtLeastOne =
  <
    A,
    K1 extends Extract<keyof A, string>,
    K2 extends Extract<keyof A, string>,
    R extends A &
      (
        | (Required<Pick<A, K1>> & { [P in K2]: undefined })
        | (Required<Pick<A, K2>> & { [P in K1]: undefined })
      ),
  >(
    key1: K1,
    key2: K2,
  ): ((arg: A, ctx: RefinementCtx) => arg is R) =>
  (arg, ctx): arg is R => {
    if (arg[key1] === undefined && arg[key2] === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `At least one of ${key1} or ${key2} must be filled`,
        path: [key1],
      })
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `At least one of ${key1} or ${key2} must be filled`,
        path: [key2],
      })
      return false
    }
    return true
  }
