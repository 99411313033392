import type React from 'react'
import {
  always,
  assocPath,
  compose,
  identity,
  mapObjIndexed,
  mergeWith,
} from 'ramda'

import type Konva from 'konva'

import {
  FloorPlanElementShape,
  type FloorPlanElementInterface,
  type FloorPlanTableInterface,
  type PositionInterface,
  type SizeInterface,
} from 'src/domain/FloorPlan/types/floorPlanElement'
import {
  getAbsoluteCenter,
  getEllipseRadius,
  getLineEndOffset,
  getNearestDiagonalAngle,
  getRectRadius,
  getRelativeCenter,
  getRotatedAroundCenterPosition,
} from 'src/service/geometry'
import { roundToNumber } from './math'

export const MAX_DIMENSION = 12
export const GRID_STEP = 30
export const ANGLE_STEP = 15

export const ShapeToMinDimension: { [key in FloorPlanElementShape]: number } = {
  [FloorPlanElementShape.Square]: GRID_STEP,
  [FloorPlanElementShape.Elliptic]: GRID_STEP,
  [FloorPlanElementShape.Rectangular]: 2 * GRID_STEP,
  [FloorPlanElementShape.DeprecatedRectangular]: 2 * GRID_STEP,
}

export const getRealDimension = (number: number) => number * GRID_STEP
export const getApiDimension = (number: number) =>
  Math.round(number / GRID_STEP)

const sanitizePosition = (p: PositionInterface): PositionInterface =>
  mapObjIndexed(Math.round, p)

export const getRealSize = (s: SizeInterface): SizeInterface =>
  mapObjIndexed(getRealDimension, s)

export const getNameLabelPosition = (
  table: FloorPlanTableInterface,
  labelSize: SizeInterface,
): PositionInterface => ({
  x: getAbsoluteCenter(table).x - labelSize.width / 2,
  y:
    getAbsoluteCenter(table).y -
    getEllipseRadius(table.size, table.rotation) +
    labelSize.height,
})

export const snapDimensionToGrid = roundToNumber(GRID_STEP)
export const snapSizeToGrid = (s: SizeInterface): SizeInterface =>
  mapObjIndexed(snapDimensionToGrid, s)

export const dragElement =
  (element: FloorPlanElementInterface) =>
  (target: Konva.Node): FloorPlanElementInterface => ({
    ...element,
    position: sanitizePosition({
      x: target.x(),
      y: target.y(),
    }),
  })

export const snapToGrid = (
  element: FloorPlanElementInterface,
): FloorPlanElementInterface => ({
  ...element,
  size: snapSizeToGrid(element.size),
})

export const transformElement = (
  element: FloorPlanElementInterface,
  node: Konva.Node,
): FloorPlanElementInterface => {
  const scaleX = node.scaleX()
  const scaleY = node.scaleY()

  node.scaleX(1)
  node.scaleY(1)

  return dragElement({
    ...element,
    size: {
      width: Math.round(node.width() * scaleX),
      height: Math.round(node.height() * scaleY),
    },
    rotation: node.rotation(),
  })(node)
}

export const toggleTableCursor =
  (isLocked?: boolean) => (event: Konva.KonvaEventObject<MouseEvent>) => {
    const stageContainer = event.target.getStage()?.container()
    if (!stageContainer) return

    const enterCursor: React.CSSProperties['cursor'] = isLocked
      ? 'not-allowed'
      : 'pointer'

    stageContainer.style.cursor =
      event.type === 'mouseenter' ? enterCursor : 'default'
  }

export const toggleCursor = toggleTableCursor()

const getCapacityPositionAngle = (
  element: FloorPlanElementInterface,
  angle: number,
) => getNearestDiagonalAngle(element.size, angle) + element.rotation

const RadiusByShape: {
  [key in FloorPlanElementShape]: typeof getEllipseRadius
} = {
  [FloorPlanElementShape.Rectangular]: getRectRadius,
  [FloorPlanElementShape.Square]: getRectRadius,
  [FloorPlanElementShape.Elliptic]: getEllipseRadius,
  [FloorPlanElementShape.DeprecatedRectangular]: getRectRadius,
}
const AngleByShape: {
  [key in FloorPlanElementShape]: typeof getCapacityPositionAngle
} = {
  [FloorPlanElementShape.Rectangular]: getCapacityPositionAngle,
  [FloorPlanElementShape.Square]: getCapacityPositionAngle,
  [FloorPlanElementShape.Elliptic]: always(0),
  [FloorPlanElementShape.DeprecatedRectangular]: always(0),
}

const DEFAULT_CAPACITY_ANGLE_POSITION = 135
export const getCapacityPosition = (
  element: FloorPlanElementInterface,
  angle: number = DEFAULT_CAPACITY_ANGLE_POSITION,
): PositionInterface => {
  const relativeRotation = angle - element.rotation

  const offsetAngle =
    AngleByShape[element.shape](element, relativeRotation) || angle

  const offsetLength = RadiusByShape[element.shape](
    element.size,
    relativeRotation,
  )

  const lineEndOffset = getLineEndOffset(offsetLength, offsetAngle)
  const elementCenter = getAbsoluteCenter(element)

  return {
    x: elementCenter.x + lineEndOffset.x,
    y: elementCenter.y - lineEndOffset.y,
  }
}

export const rotateElement =
  (angle: number) =>
  <T extends FloorPlanElementInterface>(element: T): T => ({
    ...element,
    position: sanitizePosition(getRotatedAroundCenterPosition(element, angle)),
    rotation: element.rotation + angle,
  })

export const limitDrag =
  (offset: PositionInterface, padding: number) =>
  (target: Konva.KonvaEventObject<DragEvent>['target']) => {
    const boundaryPosition = target.getClientRect() as PositionInterface
    const currentPosition = target.position() as PositionInterface

    const { x: offsetX, y: offsetY } = offset

    const absolutePositionX = boundaryPosition.x - padding + offsetX
    const absolutePositionY = boundaryPosition.y - padding + offsetY

    if (absolutePositionX < 0) target.x(currentPosition.x - absolutePositionX)
    if (absolutePositionY < 0) target.y(currentPosition.y - absolutePositionY)
  }

// compatibility with resbook's hardcoded css sizes
// https://github.com/Lunchgate/reservationsbuch/blob/4177c25b9256a6ce028a52ffd52103d049928d1d/app/scss/_res-visual-plan.scss#L116
const gridDimensionAdjustor = (dimension: number) =>
  dimension / 2 + Math.min(dimension / 2, 1)
const GridDimensionAdjustorShapeMap: {
  [key in FloorPlanElementShape]: typeof apiDimensionAdjustor
} = {
  [FloorPlanElementShape.Elliptic]: gridDimensionAdjustor,
  [FloorPlanElementShape.Square]: gridDimensionAdjustor,
  [FloorPlanElementShape.Rectangular]: identity,
  [FloorPlanElementShape.DeprecatedRectangular]: identity,
}

const adjustDimensionForGrid =
  (shape: FloorPlanElementShape) => (dimension: number) =>
    GridDimensionAdjustorShapeMap?.[shape]?.(dimension)

const apiDimensionAdjustor = (dimension: number) =>
  Math.max(dimension, 2 * dimension - 2)

const ApiDimensionAdjustorShapeMap: {
  [key in FloorPlanElementShape]: typeof apiDimensionAdjustor
} = {
  [FloorPlanElementShape.Elliptic]: apiDimensionAdjustor,
  [FloorPlanElementShape.Square]: apiDimensionAdjustor,
  [FloorPlanElementShape.Rectangular]: identity,
  [FloorPlanElementShape.DeprecatedRectangular]: identity,
}

export const adjustDimensionForApi =
  (shape: FloorPlanElementShape) => (dimension: number) =>
    ApiDimensionAdjustorShapeMap[shape](dimension)

// Compatibility with resbook's rectangular shape 90 degrees rotation.
// At 90 degrees, it is not rotated around its center (like with other angles),
// but has the rotation mocked by swapping its width and height.
// This causes a rotation around the upper left corner instead of the center and
// needs to be translated into a new, properly positioned rectangle.
export const ensureShapeCompatibility = (
  table: FloorPlanTableInterface,
): FloorPlanTableInterface => {
  if (table.shape !== FloorPlanElementShape.DeprecatedRectangular) return table
  if (table.rotation !== 90)
    return {
      ...table,
      shape: FloorPlanElementShape.Rectangular,
    }

  const relativeCenter = getRelativeCenter(table.size)
  return {
    ...table,
    shape: FloorPlanElementShape.Rectangular,
    position: {
      x: table.position.x - relativeCenter.x + relativeCenter.y,
      y: table.position.y + relativeCenter.x - relativeCenter.y,
    },
  }
}
// end compatibility

export const getGridDimension = (shape: FloorPlanElementShape) =>
  compose(getRealDimension, adjustDimensionForGrid(shape))

export const getGridSize =
  (shape: FloorPlanElementShape) =>
  (s: SizeInterface): SizeInterface =>
    mapObjIndexed(getGridDimension(shape), s)

export const getGridPosition = (p: PositionInterface): PositionInterface =>
  mapObjIndexed(getRealDimension, p)

export const getMaxDimension = (shape: FloorPlanElementShape) =>
  getGridDimension(shape)(MAX_DIMENSION)

const getMaxSize = (shape: FloorPlanElementShape): SizeInterface => ({
  width: getMaxDimension(shape),
  height: getMaxDimension(shape),
})

export const getMinDimension = (shape: FloorPlanElementShape) =>
  ShapeToMinDimension[shape]

const getMinSize = (shape: FloorPlanElementShape): SizeInterface => ({
  width: getMinDimension(shape),
  height: getMinDimension(shape),
})

export const limitSize = (
  shape: FloorPlanElementShape,
): ((size: SizeInterface) => SizeInterface) => {
  const maxSize = getMaxSize(shape)
  const minSize = getMinSize(shape)

  return compose(mergeWith(Math.min, maxSize), mergeWith(Math.max, minSize))
}

const HeightByShapeMap: { [key in FloorPlanElementShape]: number } = {
  [FloorPlanElementShape.Rectangular]: 2,
  [FloorPlanElementShape.DeprecatedRectangular]: 2,
  [FloorPlanElementShape.Elliptic]: 0,
  [FloorPlanElementShape.Square]: 0,
}

export const sizeByShape = (
  width: number,
  shape: FloorPlanElementShape,
): SizeInterface => ({
  width,
  height: HeightByShapeMap[shape] || width,
})

const calculateNewSize = (
  width: number,
  shape: FloorPlanElementShape,
): SizeInterface => {
  const newSize = getRealSize(sizeByShape(width, shape))
  return limitSize(shape)(newSize)
}

export const isTooSmall =
  (shape: FloorPlanElementShape) => (dimensions: Record<string, number>) =>
    Object.values(dimensions).some(dim => dim < getMinDimension(shape))

export const isTooLarge =
  (shape: FloorPlanElementShape) => (dimensions: Record<string, number>) =>
    Object.values(dimensions).some(
      dim => Math.round(dim) > getMaxDimension(shape),
    )

export const resizeTable = (size: number) => (table: FloorPlanTableInterface) =>
  assocPath(['size'], calculateNewSize(size, table.shape), table)
