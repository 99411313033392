import { Stack, styled } from '@mui/material'

const CenteredBox = styled(Stack)({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
})

export default CenteredBox
