export const roundToNumber =
  (target: number, roundFn = Math.round) =>
  (number: number) =>
    roundFn(number / target) * target

export const interpolate = (start: number, end: number, step = 1) => {
  const length = roundToNumber(1, Math.floor)((end - start) / step) + 1

  return Array.from({ length }, (_, i) => start + i * step)
}
