import type React from 'react'

import { LoginScreen } from 'src/components/views/Login/LoginScreen'
import useDialog from 'src/hooks/useDialog'

const withLoginGuard = (Component: React.FunctionComponent) => () => {
  const { open, handleClose } = useDialog(true)

  if (open) return <LoginScreen onLogin={handleClose} />

  return <Component />
}

export default withLoginGuard
