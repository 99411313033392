import { styled } from '@mui/material'

import { withIconContainer } from 'src/components/atoms/IconContainer'
import ChevronIcon from './chevronDown.svg'

export default ChevronIcon

export const Chevron = styled(withIconContainer(ChevronIcon))(({ theme }) => ({
  transition: theme.transitions.create('transform'),
}))

export const ChevronRightIcon = styled(Chevron)({
  transform: 'rotate(-90deg)',
})

export const ChevronLeftIcon = styled(Chevron)({
  transform: 'rotate(90deg)',
})
