import React, { type PropsWithChildren } from 'react'
import {
  Box,
  CssBaseline,
  GlobalStyles,
  Portal,
  type GlobalStylesProps,
} from '@mui/material'

import { Toaster } from 'sonner'

import CenteredBox from 'src/components/atoms/CenteredBox'
import CircularProgress from 'src/components/atoms/CircularProgress'
import Meta from 'src/components/molecules/Meta/Meta'
import theme from 'src/theme/theme'

const globalStyles: GlobalStylesProps['styles'] = {
  body: { fontSize: 13 },
}

const AppContainer = ({ children }: PropsWithChildren) => (
  <>
    <CssBaseline />
    <GlobalStyles styles={globalStyles} />
    <Meta />
    <Box
      sx={{
        ...theme.scrollStyle,
        height: '100vh',
        display: 'flex',
        '@media print': {
          height: 'auto',
        },
      }}
    >
      <React.Suspense
        fallback={
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        }
      >
        {children}
      </React.Suspense>
    </Box>
  </>
)

export const withToaster = (Component: React.FunctionComponent) => () => (
  <>
    <Portal>
      <Toaster toastOptions={{ duration: 3_000, unstyled: true }} />
    </Portal>
    <Component />
  </>
)

export const withAppContainer = (Component: React.FunctionComponent) => () => (
  <AppContainer>
    <Component />
  </AppContainer>
)
