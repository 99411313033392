import React from 'react'
import { Box } from '@mui/material'

import QuestionmarkIcon from 'src/components/atoms/QuestionmarkIcon'

const TooltipToggle = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    sx={{
      p: 0.5,
      borderRadius: '50%',
      backgroundColor: 'primaryPalette.50',
      color: 'primaryPalette.600',
      '&:hover': {
        backgroundColor: 'primaryPalette.100',
      },
      display: 'inline-flex',
      flexShrink: 0,
    }}
    {...props}
  >
    <QuestionmarkIcon size="small" />
  </Box>
))

export default TooltipToggle
