import { tap } from 'ramda'

import { noop } from '@tanstack/react-table'

import { type ApiClient } from 'src/service/api'
import { login } from 'src/service/authApi'
import { refreshToken, saveToken } from 'src/service/token'
import { handleApiError, isAuthenticationError } from 'src/types/api'

export { AUTHENTICATION_ERROR_HTTP_CODE } from 'src/service/authApi'
export { refreshToken, getToken, TokenRefreshError } from 'src/service/token'

export const authFailureHandler = (httpClient: ApiClient) => (error: Error) => {
  if (isAuthenticationError(error)) return refreshToken(httpClient)

  throw error
}

export const authenticate =
  (httpClient: ApiClient) => (username: string, password: string) =>
    login(httpClient)(username, password)
      .then(tap(r => saveToken(r.jwt)))
      .catch(handleApiError(noop))
