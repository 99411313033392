import { andThen, composeWith } from 'ramda'

import { z } from 'zod'

import type { ApiClient } from 'src/service/api'
import { HttpErrorCodes, type ApiError } from 'src/types/api'

export const AUTHENTICATION_ERROR_HTTP_CODE = HttpErrorCodes.AuthenticationError

interface LoginSuccess {
  state: 'ok'
  jwt: string
}

export const isLoginSuccess = (
  response: LoginSuccess | ApiError,
): response is LoginSuccess => response.state === 'ok'

export const login =
  (httpClient: ApiClient) => async (username: string, password: string) =>
    httpClient({
      method: 'POST',
      url: 'getJwt',
      data: {
        username,
        password,
      },
      withCredentials: true,
    }) as Promise<LoginSuccess>

const refreshTokenSchema = z
  .object({
    jwt: z.string(),
  })
  .transform(({ jwt }) => jwt)

// eslint-disable-next-line @typescript-eslint/require-await
export const fetchFreshToken = async (httpClient: ApiClient) =>
  composeWith(andThen)([
    (d: unknown) => refreshTokenSchema.parse(d),
    httpClient,
  ])({
    method: 'POST',
    url: 'refreshJwt',
    withCredentials: true,
  })

export const logout = (httpClient: ApiClient) => () =>
  httpClient({
    method: 'POST',
    url: 'logout',
    withCredentials: true,
  })
