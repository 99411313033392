import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { useTranslation } from 'react-i18next'

import dayjs from 'src/service/date'
import { languageToLocale } from 'src/service/i18n'

const withLocaleProvider =
  <T extends object>(
    Component: React.FunctionComponent<T>,
  ): React.FunctionComponent<T> =>
  props => {
    const {
      i18n: { language },
    } = useTranslation()

    React.useEffect(() => {
      dayjs.locale(languageToLocale(language))
    }, [language])

    return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={languageToLocale(language)}
      >
        <Component {...props} />
      </LocalizationProvider>
    )
  }

export default withLocaleProvider
