import { path } from 'ramda'

import { useFormContext } from 'react-hook-form'

export const useDefaultValue = ({
  name,
  defaultChecked,
}: {
  name?: string
  defaultChecked?: boolean
}) => {
  const form = useFormContext()

  if (!name || !form) return defaultChecked

  return !!path(name.split('.'), form.formState.defaultValues) || defaultChecked
}
