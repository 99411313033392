import { ErrorBoundary } from '@sentry/react'

import styles from './withGlobalErrorBoundary.module.css'

const ErrorScreen = () => (
  <div className={styles.error}>
    <div>
      <div
        role="button"
        onClick={() => window.location.reload()}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        <img src="/favicon.svg" alt="logo" />
      </div>
      <h1>There has been an error in the application</h1>
      <p>Please reload the app with the button below</p>
      <button
        type="button"
        className={styles.btn}
        onClick={() => window.location.reload()}
      >
        Reload
      </button>
      <p>If reloading doesn&apos;t help please contact us at </p>
      <p>
        <a href="tel:+41435010050">+41&nbsp;43&nbsp;501&nbsp;00&nbsp;50</a> or{' '}
        <a href="mailto:office@lunchgate.com">office@lunchgate.com</a>
      </p>
    </div>
  </div>
)

export const withGlobalErrorBoundary =
  <T extends object>(Component: React.FunctionComponent<T>) =>
  (props: T) => (
    <ErrorBoundary fallback={ErrorScreen}>
      <Component {...props} />
    </ErrorBoundary>
  )
