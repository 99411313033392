const secondary = {
  50: '#FDF4F2',
  100: '#FBE9E4',
  200: '#F8D1C6',
  300: '#F4B6A3',
  400: '#F19676',
  500: '#EE6D25',
  600: '#D46121',
  700: '#B8541C',
  800: '#964417',
  900: '#6A3010',
}

export default secondary
