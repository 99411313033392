export interface TeamMember {
  id?: number
  firstName: string
  lastName?: string
  color: string
  pinRequired: boolean
}

export enum Colors {
  blue = '#44AAFF',
  pink = '#FF83EC',
  yellow = '#FEFF44',
  green = '#57FF5E',
  white = '#FFFFFF',
}

enum TeamMemberUpdateErrorCodes {
  INVALID_PIN = 27,
}

export const TeamMemberUpdateErrors: { [K in number]: string } = {
  [TeamMemberUpdateErrorCodes.INVALID_PIN]: 'invalidPinError',
}

export interface TeamMemberUpdateInterface {
  currentPin?: string
  newPin?: string
  teamMember: TeamMember
}
