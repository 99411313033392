const SVG_SIZES = {
  small: '1rem',
  medium: '1.5rem',
  large: '1.5rem',
  inherit: 'inherit',
}
export const svgSizeFactory = (size: keyof typeof SVG_SIZES) => ({
  '> svg': {
    flexShrink: '0',
    width: '1em',
    height: '1em',
    fontSize: SVG_SIZES[size],
  },
})
export const svgPathSizeFactory = (size: string, variant = 'outlined') => ({
  ...(size === 'small' &&
    variant !== 'filled' && {
      '> svg path': {
        stroke: 'currentColor',
        strokeWidth: 1,
      },
    }),
})
