const neutral = {
  25: '#F9F9FA',
  50: '#F0F0F1',
  100: '#E1E2E3',
  200: '#C4C6C8',
  300: '#A7ABAE',
  400: '#8B9195',
  500: '#70787E',
  600: '#545F68',
  700: '#384854',
  800: '#152B36',
  900: '#011E29',
  A100: '#E1E2E3',
  A200: '#C4C6C8',
  A400: '#8B9195',
  A700: '#384854',
}

export default neutral
