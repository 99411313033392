import { type PropsWithChildren } from 'react'
import { Box } from '@mui/material'

import Footer from 'src/components/molecules/Footer'
import logo from 'src/components/views/Login/foratable-logo-dark.png'

const LandingScreenContainer = ({ children }: PropsWithChildren) => (
  <Box
    sx={theme => ({
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      gap: 8,
      background: theme.palette.grey[50],
      flex: 1,
    })}
  >
    <Box
      component="header"
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 10,
        background: 'white',
        borderBottom: 1,
        borderColor: 'border',
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          mx: 'auto',
          p: 2,
        }}
      >
        <Box component="a" href={import.meta.env.PUBLIC_FAT_URL}>
          <Box
            component="img"
            src={logo}
            sx={{
              width: 150,
            }}
          />
        </Box>
      </Box>
    </Box>
    <Box
      sx={theme => ({
        p: 4,
        background: 'white',
        border: `1px solid ${theme.palette.grey[100]}`,
        width: '100%',
        maxWidth: 512,
        mx: 'auto',
      })}
    >
      {children}
    </Box>
    <Footer />
  </Box>
)

export default LandingScreenContainer
