import { useFormControl, type RadioProps } from '@mui/material'

import CheckMark from 'src/components/atoms/CheckmarkIcon'
import ControlContainer from 'src/components/atoms/ControlContainer'
import IndeterminateIcon from 'src/components/atoms/IndeterminateIcon'

const CheckboxIcon = ({
  checked = false,
  indeterminate = false,
  fontSize,
}: {
  checked?: boolean
  indeterminate?: boolean
  fontSize?: RadioProps['size']
}) => {
  const formControlState = useFormControl()
  const isSmall = (formControlState?.size ?? fontSize) === 'small'

  return (
    <ControlContainer
      sx={{
        borderRadius: 1,
        p: isSmall ? 0 : 0.5,
      }}
      showBackground={checked || indeterminate}
      fontSize={fontSize}
    >
      {checked && <CheckMark size="small" />}
      {indeterminate && <IndeterminateIcon size="small" />}
    </ControlContainer>
  )
}

export default CheckboxIcon
