import type React from 'react'
import { forwardRef } from 'react'
import {
  Box,
  FormControlLabel,
  Switch,
  switchClasses,
  useFormControl,
  type SwitchProps,
} from '@mui/material'
import type { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel'

import { useDefaultValue } from 'src/hooks/useDefaultValue'
import { mergeSx } from 'src/theme/helpers'
import { type IconProps } from '../IconContainer'

interface ToggleProps extends Omit<SwitchProps, 'icon' | 'ref'> {
  size?: SwitchProps['size']
  label?: React.ReactNode
  labelProps?: Partial<FormControlLabelProps>
  icon?: React.FC<IconProps>
}

export const toggleHoverStyle = (size: SwitchProps['size']) => ({
  [`& .${switchClasses.thumb}`]: {
    transform: `scale(${size === 'small' ? 10 / 12 : 0.75})`,
  },
  [`& .${switchClasses.switchBase}`]: {
    [`& + .${switchClasses.track}`]: {
      backgroundColor: 'grey.700',
    },
    [`&.${switchClasses.checked}`]: {
      [`& + .${switchClasses.track}`]: {
        backgroundColor: 'primaryPalette.700',
      },
    },
  },
  [`.${switchClasses.root}:has(.${switchClasses.checked}) + div > div`]: {
    transform: `scale(${size === 'small' ? 10 / 12 : 0.75})`,
  },
})
const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ icon: Icon, size, sx, label, labelProps, ...props }, ref) => {
    const formControlState = useFormControl()
    const resolvedSize = size ?? formControlState?.size ?? 'medium'

    return (
      <FormControlLabel
        control={
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            }}
            className="switch-wrapper"
          >
            <Switch
              color="primary"
              size={resolvedSize}
              inputRef={ref}
              defaultChecked={useDefaultValue(props)}
              sx={{
                [`.${switchClasses.thumb}`]: {
                  zIndex: 2,
                },
                '+ div': {
                  color: 'grey.50',
                },
                [`&:has(.${switchClasses.checked}) + div`]: {
                  color: 'primaryPalette.600',
                },
                [`&:has(.${switchClasses.disabled}) + div`]: {
                  color: 'grey.300',
                },
                [`&:has(.${switchClasses.checked}.${switchClasses.disabled}) + div`]:
                  {
                    color: 'white',
                  },
              }}
              {...props}
            />
            {Icon && (
              <Box
                sx={{
                  pointerEvents: 'none',
                  position: 'absolute',
                  display: 'flex',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: size === 'small' ? '2.5px' : '5px',
                }}
              >
                <Icon
                  sx={theme => ({
                    transition: theme.transitions.create(
                      ['color', 'transform'],
                      {
                        duration: 200,
                      },
                    ),
                    fontSize: size === 'small' ? '0.7rem' : '0.9rem',
                  })}
                  size="inherit"
                />
              </Box>
            )}
          </Box>
        }
        size={resolvedSize}
        sx={mergeSx(
          {
            gap: 1,
            maxWidth: 'max-content',
            ':hover .switch-wrapper': toggleHoverStyle(size),
            ...(props.disabled && { pointerEvents: 'none' }),
          },
          sx,
        )}
        label={label}
        {...labelProps}
      />
    )
  },
)

export default Toggle
