import {
  Box,
  circularProgressClasses,
  keyframes,
  CircularProgress as MuiProgress,
  type CircularProgressProps,
} from '@mui/material'

const backgroundDashKeyframe = keyframes`
  0% {
    stroke-dashoffset: 128;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -128;
  }
`

const foregroundDashKeyframe = keyframes`
  0% {
    stroke-dashoffset: 32;
  }
  50% {
    stroke-dashoffset: -128;
  }
  100% {
    stroke-dashoffset: -288;
  }
`

const StyledProgress = ({ sx, ...props }: CircularProgressProps) => (
  <MuiProgress
    {...props}
    sx={{
      ...sx,
      position: 'absolute',
      animation: 'none',
      transform: 'rotate(-90deg)',
    }}
  />
)

const CircularProgress = ({ size = 40, ...props }: CircularProgressProps) => (
  <Box
    position="relative"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: size,
      height: size,
    }}
  >
    <StyledProgress
      sx={{
        color: theme => theme.palette.grey['50'],
      }}
      variant="determinate"
      value={100}
      {...props}
      size={size}
    />
    <StyledProgress
      sx={{
        color: theme => theme.palette.primaryPalette['500'],
        [`.${circularProgressClasses.circle}`]: {
          strokeDasharray: 128,
          animation: `${backgroundDashKeyframe} 1.4s ease-in-out infinite`,
        },
      }}
      {...props}
      size={size}
    />
    <StyledProgress
      sx={{
        color: theme => theme.palette.primaryPalette['800'],
        [`.${circularProgressClasses.circle}`]: {
          strokeDasharray: '32, 128',
          animation: `${foregroundDashKeyframe} 1.4s ease-in-out infinite`,
        },
      }}
      {...props}
      size={size}
    />
  </Box>
)

export default CircularProgress
