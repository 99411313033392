import React, { useMemo } from 'react'
import { andThen, composeWith, uniq } from 'ramda'

import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
  type DefaultError,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { useRestaurantHash } from 'src/context/state/atoms/selectedRestaurant'
import { useApiClient } from 'src/hooks/useApiClient'
import { convertKeysToCamelCase, type ApiClient } from 'src/service/api'
import {
  allRestaurantsSchema,
  type AllRestaurants,
} from 'src/types/allRestaurants'

const CACHE_KEY = ['all-restaurants']

// eslint-disable-next-line @typescript-eslint/require-await
const getAllRestaurants = (httpClient: ApiClient) => async () =>
  composeWith(andThen)([
    (d: unknown) => allRestaurantsSchema.parse(d),
    convertKeysToCamelCase,
    httpClient,
  ])({
    url: 'restaurants/all',
  })

const useRestaurantsQueryOptions = <T>(preventLogout = false) => {
  const apiClient = useApiClient({ handleLogout: !preventLogout })

  return useMemo(
    () =>
      queryOptions<AllRestaurants, DefaultError, T>({
        queryKey: CACHE_KEY,
        queryFn: getAllRestaurants(apiClient.post),
        staleTime: Infinity,
        refetchInterval: false,
      }),
    [apiClient.post],
  )
}

const useRestaurantsQuery = <T>(
  options: Partial<
    UseSuspenseQueryOptions<AllRestaurants, DefaultError, T>
  > = {},
) =>
  useSuspenseQuery({
    ...useRestaurantsQueryOptions<T>(),
    ...options,
  })

export const useRestaurantsInChainQuery = () =>
  useRestaurantsQuery({ select: r => r.chaines })

export const useAllRestaurantsQuery = () =>
  useRestaurantsQuery({ select: r => r.restaurants })

export const useIsAdminQuery = () =>
  useRestaurantsQuery({ select: r => r.superadmin })

export const useRestaurantsQueryCallback = (preventLogout = false) => {
  const queryClient = useQueryClient()
  const options = useRestaurantsQueryOptions(preventLogout)

  return React.useCallback(
    () => queryClient.ensureQueryData({ ...options, retry: false }),
    [queryClient, options],
  )
}
export const useRestaurantsIds = () => {
  const restaurantHash = useRestaurantHash()

  return useRestaurantsQuery({
    select: data =>
      // TODO remove when chaines is no longer an empty array for superadmins
      uniq([...data.chaines.map(r => r.hash), restaurantHash]),
  })
}

export const useCurrentRestaurant = () => {
  const restaurantHash = useRestaurantHash()
  const { data: allRestaurants } = useAllRestaurantsQuery()

  return useMemo(
    () => allRestaurants.find(({ hash }) => hash === restaurantHash)!,
    [allRestaurants, restaurantHash],
  )
}
