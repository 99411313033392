import React from 'react'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Locize, { type LocizeBackendOptions } from 'i18next-locize-backend'
import { initReactI18next, useTranslation } from 'react-i18next'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'
import zodDe from 'zod-i18n-map/locales/de/zod.json'
import zodEn from 'zod-i18n-map/locales/en/zod.json'
import zodFr from 'zod-i18n-map/locales/fr/zod.json'
import zodHr from 'zod-i18n-map/locales/hr-HR/zod.json'
import zodIt from 'zod-i18n-map/locales/it/zod.json'

const FALLBACK_SUPPORTED_LANGUAGES = ['de', 'en']

const fallbackDetector = {
  name: 'fallbackLanguage',
  lookup: () => 'de',
  cacheUserLanguage: () => {},
}

export const languageToLocale = (language: string) => {
  switch (language) {
    case 'en':
      return 'en-gb'
    default:
      return language
  }
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(fallbackDetector)

const locize: Locize = new Locize(
  {
    projectId: import.meta.env.PUBLIC_LOCIZE_PROJECT_ID,
    apiKey: import.meta.env.PUBLIC_LOCIZE_API_KEY,
    private: false,
    translatedPercentageThreshold: 0.2,
  },
  (_err, opts: object) =>
    i18n
      .use(locize)
      .use(initReactI18next)
      .use(languageDetector)
      .init<LocizeBackendOptions>({
        ...opts,
        saveMissing: true,
        load: 'all',
        fallbackLng: false,
        cleanCode: true,
        appendNamespaceToCIMode: true,
        debug: import.meta.env.MODE !== 'production',
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        partialBundledLanguages: true,
        resources: {
          en: { zod: zodEn },
          de: { zod: zodDe },
          fr: { zod: zodFr },
          it: { zod: zodIt },
          hr: { zod: zodHr },
        },
        detection: {
          order: ['cookie', 'navigator', 'fallbackLanguage'],
          lookupCookie: 'i18n_language',
          convertDetectedLanguage: lng => lng.split('-')[0]!,
          cookieDomain: window.location.hostname.split('.').slice(-2).join('.'),
          cookieMinutes: 60 * 24 * 7,
          caches: ['cookie'],
          excludeCacheFor: ['cimode'],
          cookieOptions: { sameSite: 'strict' },
        },
      }),
)

z.setErrorMap(
  makeZodI18nMap({
    ns: ['zod', 'translation'],
    handlePath: false,
  }),
)

export const useSupportedLanguages = () => {
  const {
    i18n: {
      options: { supportedLngs },
    },
  } = useTranslation()

  return React.useMemo(() => {
    const supportedLanguages =
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      supportedLngs || FALLBACK_SUPPORTED_LANGUAGES

    return supportedLanguages.filter(
      lng => lng !== 'cimode' || import.meta.env.MODE !== 'production',
    )
  }, [supportedLngs])
}
export default i18n
