export const camelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

const isSnakeCase = (str: string): boolean =>
  str.includes('_') || str.includes('-')

export const snakeToCamelCase = (str: string): string =>
  isSnakeCase(str)
    ? str
        .toLowerCase()
        .replace(/([-_][a-z])/g, group =>
          group.toUpperCase().replace('-', '').replace('_', ''),
        )
    : str

export const currencyFormatter = (locale = 'de-CH', currency = 'CHF') =>
  new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
  })

const swissNumberFormat = currencyFormatter()

export const formatCHF = (value: number) => swissNumberFormat.format(value)

export const getEmoji = (text: string) => {
  try {
    return text.match(/\p{RGI_Emoji}/v)?.[0] ?? null
  } catch {
    return null
  }
}
