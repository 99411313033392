import {
  TodoItemStatus,
  type TodoItem,
  type TodoItemTypeEnum,
} from 'src/types/notification'

interface RawEventData {
  itemId: number
  aggregateId: string
  eventType: TodoItemTypeEnum
}

interface RawApiData {
  itemId: number
  aggregateId: string
  status: TodoItemStatus
  eventType: TodoItemTypeEnum
}

export const fromEventData = (data: RawEventData): TodoItem => ({
  id: data.itemId.toString(),
  reservationId: Number(data.aggregateId),
  status: TodoItemStatus.Open,
  type: data.eventType,
})

export const fromApi = (data: RawApiData): TodoItem => ({
  id: data.itemId.toString(),
  reservationId: Number(data.aggregateId),
  status: data.status,
  type: data.eventType,
})
