import React from 'react'

import { useTranslation } from 'react-i18next'

export const useLanguageChange = () => {
  const {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    i18n: { changeLanguage },
  } = useTranslation()

  return React.useCallback(
    async (language: string) => changeLanguage(language),
    [changeLanguage],
  )
}
