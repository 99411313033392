import { compose } from 'ramda'

import {
  FloorPlanElementShape,
  FloorPlanElementType,
  type FloorPlanElementInterface,
  type FloorPlanTableInterface,
  type FurnitureInterface,
} from 'src/domain/FloorPlan/types/floorPlanElement'
import {
  DEFAULT_FURNITURE_BG,
  SECONDARY_TABLE_BG,
} from 'src/service/floorPlanElement'
import {
  adjustDimensionForApi,
  ensureShapeCompatibility,
  getApiDimension,
  getGridPosition,
  getGridSize,
  limitSize,
  rotateElement,
  sizeByShape,
} from 'src/service/tableGrid'
import {
  elementSchema,
  tableSchema,
  type ApiFurniture,
  type ApiTable,
} from 'src/types/configApi'
import {
  type TableNoteInterface,
  type TableNoteUpdateInterface,
  type TablesOrderUpdateInterface,
} from 'src/types/table'

export const tableToApiPayload = (table: ApiTable) => ({
  ...table,
  table_id: table.id,
})

export const tableToApi = (rotatedTable: FloorPlanTableInterface): ApiTable => {
  const table = rotateElement(-rotatedTable.rotation)(rotatedTable)

  return {
    ...table,
    id: table.id!,
    elementType: FloorPlanElementType.Table,
    area_id: table.areaId,
    name: table.name,
    places: table.capacity,
    size: adjustDimensionForApi(rotatedTable.shape)(
      getApiDimension(table.size.width),
    ),
    left: table.position.x,
    top: table.position.y,
    type: table.shape,
    rotation: rotatedTable.rotation,
  }
}

export const furnitureToApiPayload = (furniture: ApiFurniture) => ({
  ...furniture,
  room_element_id: furniture.id,
})

export const furnitureToApi = (element: FurnitureInterface): ApiFurniture => ({
  ...element,
  id: element.id!,
  area_id: element.areaId,
  elementType: FloorPlanElementType.Furniture,
  left: element.position.x,
  top: element.position.y,
  width: element.size.width,
  height: element.size.height,
})

export const tableFromApi = (rawTable: ApiTable): FloorPlanTableInterface =>
  ensureShapeCompatibility({
    ...rotateElement(rawTable.rotation)({
      id: rawTable.id,
      areaId: rawTable.area_id,
      rotation: 0,
      position: {
        x: rawTable.left,
        y: rawTable.top,
      },
      size: compose(
        limitSize(rawTable.type),
        getGridSize(rawTable.type),
        sizeByShape,
      )(rawTable.size, rawTable.type),
      shape: rawTable.type,
      color: SECONDARY_TABLE_BG,
      type: FloorPlanElementType.Table,
    }),
    type: FloorPlanElementType.Table,
    name: rawTable.name.toString(),
    capacity: rawTable.places,
  })

export const furnitureFromApi = (
  rawFurniture: ApiFurniture,
): FurnitureInterface => ({
  id: rawFurniture.id,
  areaId: rawFurniture.area_id,
  rotation: 0,
  position: {
    x: rawFurniture.left,
    y: rawFurniture.top,
  },
  size: {
    width: rawFurniture.width,
    height: rawFurniture.height,
  },
  shape: FloorPlanElementShape.Rectangular,
  color: rawFurniture.color,
  type: FloorPlanElementType.Furniture,
})

const defaultRoomElementProps: Omit<
  FloorPlanElementInterface,
  'shape' | 'size' | 'color' | 'type'
> = {
  id: null,
  areaId: 0,
  position: getGridPosition({
    x: 1,
    y: 1,
  }),
  rotation: 0,
}

const defaultTableProps: Omit<
  FloorPlanTableInterface,
  'shape' | 'size' | 'capacity'
> = {
  ...defaultRoomElementProps,
  name: '',
  color: SECONDARY_TABLE_BG,
  type: FloorPlanElementType.Table,
}

export const newSquareTable = (): FloorPlanTableInterface => ({
  ...defaultTableProps,
  shape: FloorPlanElementShape.Square,
  capacity: 2,
  size: getGridSize(FloorPlanElementShape.Square)({
    width: 2,
    height: 2,
  }),
})

export const newRectangularTable = (): FloorPlanTableInterface => ({
  ...defaultTableProps,
  shape: FloorPlanElementShape.Rectangular,
  capacity: 4,
  size: getGridSize(FloorPlanElementShape.Rectangular)({
    width: 4,
    height: 2,
  }),
})

export const newRoundTable = (): FloorPlanTableInterface => ({
  ...defaultTableProps,
  shape: FloorPlanElementShape.Elliptic,
  capacity: 2,
  size: getGridSize(FloorPlanElementShape.Elliptic)({
    width: 2,
    height: 2,
  }),
})

export const newFurniture = (): FurnitureInterface => ({
  ...defaultRoomElementProps,
  color: DEFAULT_FURNITURE_BG,
  shape: FloorPlanElementShape.Rectangular,
  type: FloorPlanElementType.Furniture,
  size: getGridSize(FloorPlanElementShape.Rectangular)({
    width: 6,
    height: 2,
  }),
})

export const order = (
  tables: FloorPlanTableInterface[],
): TablesOrderUpdateInterface =>
  Object.fromEntries(tables.map((table, index) => [table.id!, index]))

export const tableFromEventData = (table: unknown) => tableSchema.parse(table)
export const tableRemovedEventFromData = (table: unknown) =>
  elementSchema.pick({ id: true }).parse(table)

interface RawNote {
  table_id: number
  note: string
}

export const tableNoteFromApi = (note: RawNote): TableNoteInterface => ({
  tableId: note.table_id,
  note: note.note,
})

interface RawPayload {
  date: string
  serviceTimeId: number
  content: string
  tableId: number
}

export const tableNoteUpdateFromEventData = (
  payload: RawPayload,
): TableNoteUpdateInterface => ({
  date: new Date(payload.date),
  serviceTimeId: payload.serviceTimeId,
  tableNote: {
    note: payload.content,
    tableId: payload.tableId,
  },
})
