import {
  type TodoItemStatus,
  type TodoItemStatusChange,
  type TodoItemTypeEnum,
} from 'src/types/notification'
import dayjs from './date'

interface RawEventData {
  itemId: number
  aggregateId: string
  status: TodoItemStatus
  eventType: TodoItemTypeEnum
  createdAt: string
  withHistory: boolean
}

export const fromEventData = (data: RawEventData): TodoItemStatusChange => ({
  id: data.itemId.toString(),
  reservationId: Number(data.aggregateId),
  status: data.status,
  todoId: data.itemId.toString(),
  type: data.eventType,
  date: dayjs(data.createdAt).toDate(),
  all: data.withHistory,
})
