import { styled } from '@mui/material'

import { withIconContainer } from 'src/components/atoms/IconContainer'
import ChevronFilledIcon from './filled.svg'

const ChevronIcon = withIconContainer(undefined, ChevronFilledIcon)

export default ChevronIcon

export const ChevronFilledDown = ChevronIcon
export const ChevronFilledUp = styled(ChevronIcon)({
  transform: 'rotate(180deg)',
})
