import dayjs from 'src/service/date'
import { type TodoItemsRead } from 'src/types/notification'

interface RawEventData {
  itemId: number
  occuredOn: string
}

export const fromEventData = (data: RawEventData): TodoItemsRead => ({
  id: data.itemId.toString(),
  date: dayjs(data.occuredOn).toDate(),
})
