import {
  Box,
  useFormControl,
  type BoxProps,
  type RadioProps,
  type Theme,
} from '@mui/material'

import { mergeSx } from 'src/theme/helpers'

const ControlContainer = ({
  sx,
  showBackground,
  fontSize,
  ...props
}: BoxProps & {
  showBackground: boolean
  fontSize?: RadioProps['size']
}) => {
  const formControlState = useFormControl()
  const isSmall = (formControlState?.size ?? fontSize) === 'small'

  return (
    <Box
      {...props}
      sx={mergeSx(
        {
          width: isSmall ? 16 : 24,
          height: isSmall ? 16 : 24,
          backgroundColor: 'white',
          color: 'primary.contrastText',
          boxSizing: 'border-box',
          ...(showBackground && {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            'input:hover ~ &': {
              backgroundColor: 'primary.dark',
            },
          }),
          ...(!showBackground && {
            borderStyle: 'solid',
            borderColor: 'grey.200',
            borderWidth: 1,
            'input:hover ~ &': {
              borderColor: 'primaryPalette.400',
              backgroundColor: 'primaryPalette.50',
            },
          }),
          'input[disabled] ~ &': {
            color: 'grey.700',
            backgroundColor: 'grey.50',
            borderColor: 'grey.100',
          },
          transition: (theme: Theme) =>
            theme.transitions.create(['background-color', 'padding'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.standard,
            }),
        },
        sx,
      )}
    />
  )
}

export default ControlContainer
