import { type Theme } from '@mui/material'
import { pink } from '@mui/material/colors'
import { type PaletteColor } from '@mui/material/styles'
import { type PaletteOptions } from '@mui/material/styles/createPalette'

import error from 'src/theme/colors/error'
import neutral from 'src/theme/colors/neutral'
import primary from 'src/theme/colors/primary'
import secondary from 'src/theme/colors/secondary'
import success from 'src/theme/colors/success'
import warning from 'src/theme/colors/warning'

export default (currentTheme: Theme): PaletteOptions => {
  const { augmentColor } = currentTheme.palette
  const augument = (color: string) => augmentColor({ color: { main: color } })

  const createColor = (palette: typeof error): PaletteColor => ({
    lighter: palette['100'],
    light: palette['300'],
    main: palette['500'],
    dark: palette['700'],
    darker: palette['900'],
    contrastText: augument(palette['500']).contrastText,
  })

  return {
    border: neutral['100'],
    text: {
      primary: neutral['900'],
      secondary: neutral['800'],
      tertiary: neutral['600'],
      quaternary: neutral['500'],
      disabled: neutral['400'],
    },
    action: {
      disabledBackground: neutral['100'],
      disabled: neutral['300'],
    },
    primary: createColor(primary),
    secondary: createColor(secondary),
    error: createColor(error),
    info: createColor(primary),
    warning: createColor(warning),
    neutral: createColor(neutral),
    success: {
      ...createColor(success),
      contrastText: 'white',
    },
    light: augument(neutral['100']),
    attention: augument(pink['400']),
    lunchgateSuccess: {
      ...augument('#00af83'),
      contrastText: 'white',
    },
    paper: augument('#FFFFFF'),
    grey: neutral,
    primaryPalette: primary,
    errorPalette: error,
    warningPalette: warning,
    successPalette: success,
  }
}
