import { andThen, composeWith, prop } from 'ramda'

import axios, { type AxiosError, type AxiosRequestConfig } from 'axios'
import { CronTime } from 'cron'
import type { CamelCase, SnakeCase } from 'type-fest'

import {
  ApiError,
  HttpErrorCodes,
  isApiErrorResponse,
  type ApiRequest,
  type ApiResponse,
} from 'src/types/api'
import { getUtcDayStart } from './date'
import { camelToSnakeCase, snakeToCamelCase } from './text'

axios.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (
      isApiErrorResponse(error) &&
      error.response.status &&
      Object.values(HttpErrorCodes).includes(error.response.status)
    )
      return Promise.reject(
        new ApiError(
          error.response.data.message,
          error.response.data.long_message,
          error.response.data.error_number,
          error.response.status,
        ),
      )

    return Promise.reject(error)
  },
)

export type ApiClient = (config: ApiRequest) => ApiResponse

const apiClient = <T>(url: string, config: AxiosRequestConfig<T>): Promise<T> =>
  composeWith(andThen)([prop('data') as () => Promise<T>, axios])(url, config)

export const postRequest: ApiClient = ({ url, ...config }) =>
  apiClient(url, {
    method: 'POST',
    ...config,
  })

export const getRequest: ApiClient = ({ url, ...config }) =>
  apiClient(url, {
    ...config,
    method: 'GET',
  })

interface Modifier<T, V> {
  key?: (key: string) => string
  value?: (value: T) => V
}

type RecursiveFunction<T, V> = (obj: T) => V

const applyRecursively =
  <T, V>(modifiers: Modifier<T, V>): RecursiveFunction<T, V> =>
  (obj: T): V => {
    if (Array.isArray(obj)) return obj.map(applyRecursively(modifiers)) as V
    if (obj?.constructor !== Object) return (modifiers.value?.(obj) ?? obj) as V

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        modifiers.key?.(key) ?? key,
        applyRecursively(modifiers)(value as T),
      ]),
    ) as V
  }

export const convertKeys = <T, V>(converter: (key: string) => string) =>
  applyRecursively<T, V>({ key: converter })

export const convertKeysToCamelCase: <T>(input: T) => {
  [K in keyof T as CamelCase<K>]: T[K]
} = convertKeys(snakeToCamelCase)

export const convertKeysToSnakeCase: <T>(input: T) => {
  [K in keyof T as SnakeCase<K>]: T[K]
} = convertKeys(camelToSnakeCase)

export const dateSerializer = <T>(value: Date | T) => {
  if (!(value instanceof Date)) return value

  return value.toISOString()
}

const dateNormalizer = <T>(value: Date | T) => {
  if (!(value instanceof Date)) return value

  return getUtcDayStart(value)
}

export const convertDatesToNormalized = applyRecursively({
  value: dateNormalizer,
})

const isNumeric = (n: string) => {
  const floatn = parseFloat(n)
  return !Number.isNaN(floatn) && Number.isFinite(floatn)
}

export const numericSerializer = <T>(value: T) => {
  if (typeof value === 'string' && isNumeric(value))
    return Number.parseFloat(value)

  return value
}

export const serializeValues = (
  ...serializers: (<T>(...args: T[]) => string | number | T)[]
) => {
  const composeReducer = <T>(value: string | number | T) =>
    serializers.reduce((result, serializer) => serializer(result), value)

  return applyRecursively({ value: composeReducer })
}

export const getFlatPaginatedItems = <T>(pages: { data: T[] }[]) =>
  pages.flatMap(({ data }) => data)

export const getFlatPaginatedItemsLength = <T>(pages: { data: T[] }[]) =>
  getFlatPaginatedItems(pages).length

export const create1MinuteCronTime = () => {
  const secondOffset = Math.round(59 * Math.random())

  return new CronTime(`${secondOffset} * * * * *`)
}

export const create5MinuteCronTime = () => {
  const secondOffset = Math.round(59 * Math.random())
  const minuteOffset = Math.round(4 * Math.random())

  return new CronTime(`${secondOffset} ${minuteOffset}-59/5 * * * *`)
}
