import React from 'react'
import { compose } from 'ramda'

import {
  withAppContainer,
  withToaster,
} from 'src/components/molecules/AppContainer/AppContainer'
import { withHelmet } from 'src/components/molecules/Meta/Meta'
import withLoginGuard from 'src/components/views/Login/withLoginGuard'
import { withSseClientProvider } from 'src/context/service/SseClientContext'
import { withAppProvider } from 'src/hoc/withAppProvider'
import { withGlobalErrorBoundary } from 'src/hoc/withGlobalErrorBoundary'

const App = React.lazy(() => import('src/components/views/App/App'))
const MaintenanceMode = React.lazy(
  () => import('src/components/views/Login/MaintenanceMode'),
)

const isMaintenanceMode = import.meta.env.PUBLIC_MAINTENANCE_MODE

const AppWithLoginGuard = withLoginGuard(App)

const AppView = () => {
  if (isMaintenanceMode) return <MaintenanceMode />

  return <AppWithLoginGuard />
}

export default compose(
  withGlobalErrorBoundary,
  withSseClientProvider,
  withHelmet,
  withAppProvider,
  withAppContainer,
  withToaster,
)(AppView)
