import { Divider, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useLanguageChange } from 'src/hooks/useLanguageChange'
import { useSupportedLanguages } from 'src/service/i18n'

export const LoginLanguageToggle = () => {
  const { i18n } = useTranslation()
  const changeLanguageHandler = useLanguageChange()
  const supportedLanguages = useSupportedLanguages()

  return (
    <Stack
      direction="column"
      spacing={0.5}
      sx={{
        pt: 0.5,
      }}
      alignItems="center"
    >
      <Stack
        direction="row"
        divider={
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
              borderColor: 'light.main',
            }}
          />
        }
        spacing={1}
        typography="labelTiny"
        sx={{
          textTransform: 'uppercase',
          p: 1,
        }}
      >
        {supportedLanguages.map(language => (
          <Typography
            key={language}
            onClick={() => changeLanguageHandler(language)}
            color={i18n.language === language ? 'neutral.dark' : 'neutral.main'}
            sx={{
              cursor: 'pointer',
              fontWeight: i18n.language === language ? 800 : 'inherit',
            }}
          >
            {language}
          </Typography>
        ))}
      </Stack>
    </Stack>
  )
}
