import { z } from 'zod'

const infoSchema = z
  .object({
    hash: z.string(),
    restaurantName: z.string(),
    id: z.number(),
    city: z.string(),
  })
  .transform(({ restaurantName, ...data }) => ({
    ...data,
    name: restaurantName,
  }))

export type RestaurantInterface = z.infer<typeof infoSchema>

export const allRestaurantsSchema = z.object({
  info: infoSchema,
  chaines: z.array(infoSchema),
  restaurants: z.array(infoSchema),
  superadmin: z.number().transform(sa => sa === 1),
})

export type AllRestaurants = z.infer<typeof allRestaurantsSchema>
