import { useMutation } from '@tanstack/react-query'

import { getApiUrl } from 'src/context/state/ApiClientConfigContext'
import { logout } from 'src/service/authApi'
import { authenticate } from 'src/service/authentication'
import { postRequest } from 'src/service/reservationsBookApiClient'
import { removeToken } from 'src/service/token'

interface Auth {
  username: string
  password: string
}

const useNoAuthClient = () => {
  const { apiUrl } = getApiUrl()

  return postRequest(apiUrl)
}

export const useAuthMutation = () => {
  const apiClient = useNoAuthClient()

  return useMutation({
    mutationFn: async (auth: Auth) =>
      authenticate(apiClient)(auth.username, auth.password),
  })
}

let logoutHandler: Promise<unknown> | null = null

export const useLogoutMutation = () => {
  const apiClient = useNoAuthClient()

  return useMutation({
    mutationFn: async () => {
      logoutHandler =
        logoutHandler ??
        logout(apiClient)().finally(() => (logoutHandler = null))

      await logoutHandler
      removeToken()
      await new Promise(() => {
        window.location.reload()
      })
    },
  })
}
