import '@fontsource/roboto/latin-300.css'
import '@fontsource/roboto/latin-400.css'
import '@fontsource/roboto/latin-500.css'
import '@fontsource/roboto/latin-700.css'

import React from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { QueryClientProvider } from '@tanstack/react-query'
import { I18nextProvider } from 'react-i18next'

import CenteredBox from 'src/components/atoms/CenteredBox'
import CircularProgress from 'src/components/atoms/CircularProgress'
import withLocaleProvider from 'src/hoc/withLocaleProvider'
import i18n from 'src/service/i18n'
import queryClient from 'src/service/queryClient'
import theme from 'src/theme/theme'

const AppProvider = ({ children }: { children: React.ReactNode }) => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <QueryClientProvider client={queryClient}>
          <React.Suspense
            fallback={
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            }
          >
            <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
          </React.Suspense>
        </QueryClientProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>
)

export default AppProvider

export const withAppProvider = <T extends object>(
  Component: React.FunctionComponent<T>,
) => {
  const ComponentWithConfigProvider = withLocaleProvider(Component)

  return (props: T) => (
    <AppProvider>
      <ComponentWithConfigProvider {...props} />
    </AppProvider>
  )
}
