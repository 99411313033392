import React, { useCallback } from 'react'

const useDialog = (initialOpen = false) => {
  const [open, setOpen] = React.useState(initialOpen)

  const [hasBeenOpened, setHasBeenOpened] = React.useState(initialOpen)

  return {
    handleClose: useCallback(() => setOpen(false), []),
    handleOpen: useCallback(() => {
      setOpen(true)
      setHasBeenOpened(true)
    }, []),
    open,
    hasBeenOpened,
  }
}

export default useDialog
