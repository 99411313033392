import { type ServiceTimeInterface, type TableLock } from 'src/types/configApi'
import { type ReservationInterface } from 'src/types/reservation'

export type TablesOrderUpdateInterface = Record<
  NonNullable<TableInterface['id']>,
  number
>

export enum TableCheckInStatusEnum {
  Pending = 'pending',
  CheckedIn = 'checkedIn',
  CheckedOut = 'checkedOut',
}

export interface TableOccupancyInterface {
  tableId: TableInterface['id']
  checkInStatus: TableCheckInStatusEnum
  seatCount: number
}

export type TableLockInterface = TableLock

export interface TableInterface {
  id: number | null
  areaId: number
  name: string
  capacity: number
}

export interface TableOccupancyStatusUpdateInterface {
  tableId: number
  reservationId: number
  checkInStatus: TableCheckInStatusEnum
}

export interface TableNoteInterface {
  tableId: number
  note: string
}

export interface TableNoteUpdateInterface {
  tableNote: TableNoteInterface
  serviceTimeId: ServiceTimeInterface['id']
  date: Date
}

export interface TableLockUpdateInterface {
  tableId: number
  locked: boolean
  serviceTimeId: number
  date: Date
}

export interface OccupanciesUpdateInterface {
  reservationId: ReservationInterface['id']
  occupancies: TableOccupancyInterface[]
}
