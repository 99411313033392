import type React from 'react'

import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

const releaseIsProd = import.meta.env.MODE === 'production'

const Meta = () => {
  const { t } = useTranslation()

  const documentTitle = t('common.meta.title')

  return (
    <Helmet>
      <title>{documentTitle}</title>
      <meta name="application-name" content={documentTitle} />
      <meta name="apple-mobile-web-app-title" content={documentTitle} />
      {releaseIsProd && (
        <script
          id="usercentrics-cmp"
          src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          data-settings-id="IQ4Hyc_LCgWpZ7"
          async
        />
      )}
    </Helmet>
  )
}

export default Meta

const helmetContext = {}

export const withHelmet = (Component: React.FunctionComponent) => () => (
  <HelmetProvider context={helmetContext}>
    <Component />
  </HelmetProvider>
)
