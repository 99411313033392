import { type ApiClient } from 'src/service/api'
import { fetchFreshToken } from 'src/service/authApi'
import { isAuthenticationError, TokenRefreshError } from 'src/types/api'
import { persist, remove, retrieve } from './tokenRepository'

const saveToken = persist
const getToken = retrieve
const removeToken = remove

const refreshFailureHandler = (error: Error) => {
  if (isAuthenticationError(error)) throw new TokenRefreshError()

  throw error
}

let refreshInProgress: Promise<void> | null = null

const refreshToken = async (httpClient: ApiClient) => {
  remove()
  if (refreshInProgress) return refreshInProgress
  refreshInProgress = fetchFreshToken(httpClient)
    .then(persist)
    .catch(refreshFailureHandler)
    .finally(() => (refreshInProgress = null))
  return refreshInProgress
}

export { saveToken, getToken, refreshToken, TokenRefreshError, removeToken }
