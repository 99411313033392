import React from 'react'
import {
  Button,
  styled,
  useThemeProps,
  type ButtonProps,
  type IconButtonProps as MuiIconButtonProps,
} from '@mui/material'

const IconButtonRoot = styled(Button, {
  name: 'IconButton',
  overridesResolver: (props, styles) => [styles.root],
})({})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface IconButtonProps extends ButtonProps {
  iconSize?: ButtonProps['size']
  color?: ButtonProps['color'] | MuiIconButtonProps['color']
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ iconSize, ...inProps }, ref) => {
    const { children, ...props } = useThemeProps({
      props: inProps,
      name: 'IconButton',
    })

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <IconButtonRoot ownerState={props} {...props} ref={ref}>
        {React.cloneElement(children as React.ReactElement, {
          size: iconSize ?? props.size,
        })}
      </IconButtonRoot>
    )
  },
)

export default IconButton
