import {
  type ChangeEvent,
  type MouseEvent,
  type MouseEventHandler,
} from 'react'
import { assocPath, complement, compose, hasPath, when, type Path } from 'ramda'
import { type SelectChangeEvent } from '@mui/material'

export const addProp =
  <T, U extends object, V>(valuePath: Path) =>
  (value: T | undefined | null) =>
  (apiClient: (input: U) => V): ((val: U) => V) => {
    if (value == null) return apiClient

    return compose(
      apiClient,
      when(
        complement(hasPath(valuePath as string[])),
        assocPath<T, U>(valuePath, value),
      ),
    )
  }

export const withStoppedPropagation =
  (fn?: MouseEventHandler) =>
  (event: MouseEvent): void => {
    event.stopPropagation()
    return fn?.(event)
  }

export const withTargetValue =
  <U>(fn: (value: U) => void) =>
  (
    event:
      | SelectChangeEvent<U>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) =>
    fn(event.target.value as U)

export const withTargetChecked =
  <T extends HTMLInputElement>(fn: (value: boolean) => void) =>
  (event: ChangeEvent<T>) =>
    fn(event.target.checked)
