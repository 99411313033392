import type React from 'react'
import { Tooltip as MuiTooltip, type TooltipProps } from '@mui/material'

import TooltipToggle from 'src/components/atoms/Tooltip/TooltipToggle'

interface Props {
  text?: React.ReactNode
  placement?: TooltipProps['placement']
}

const Tooltip = ({ text, placement = 'bottom-start' }: Props) => {
  if (!text) return null

  return (
    <MuiTooltip title={text} arrow placement={placement}>
      <TooltipToggle />
    </MuiTooltip>
  )
}

export default Tooltip
