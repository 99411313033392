import { Box, useFormControl, type RadioProps } from '@mui/material'

import ControlContainer from 'src/components/atoms/ControlContainer'

const RadioIcon = ({
  checked = false,
  fontSize,
}: {
  checked?: boolean
  fontSize?: RadioProps['size']
}) => {
  const formControlState = useFormControl()
  const isSmall = (formControlState?.size ?? fontSize) === 'small'

  return (
    <ControlContainer
      sx={{
        borderRadius: '50%',
        p: isSmall ? '5px' : '8px',
        'input:hover:not([disabled]) ~ &': {
          p: isSmall ? '3px' : '4px',
        },
      }}
      showBackground={checked}
      fontSize={fontSize}
    >
      {checked && (
        <Box
          sx={{
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
          }}
        />
      )}
    </ControlContainer>
  )
}

export default RadioIcon
