import { compose, tap } from 'ramda'

import * as Sentry from '@sentry/browser'

import { getApiUrl } from './apiUrl'

export const initGlobalSentry = (dsn: string, releaseProject: string) => {
  const config = getApiUrl()

  Sentry.init({
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        ...(config?.apiUrl && { networkDetailAllowUrls: [config.apiUrl] }),
      }),
      Sentry.browserProfilingIntegration(),
    ],
    dsn,
    environment: import.meta.env.MODE,
    release: `${releaseProject}@${
      import.meta.env.PUBLIC_RELEASE_VERSION
    }`.replaceAll('/', '_'),
    tracesSampleRate: import.meta.env.PUBLIC_SENTRY_SAMPLE_RATE,
    sampleRate: import.meta.env.PUBLIC_SENTRY_SAMPLE_RATE,
    profilesSampleRate:
      import.meta.env.MODE === 'production'
        ? import.meta.env.PUBLIC_SENTRY_SAMPLE_RATE
        : 0,
    replaysOnErrorSampleRate: import.meta.env.MODE === 'production' ? 1 : 0,
  })
}

export const captureException: typeof Sentry.captureException = compose(
  Sentry.captureException,
  tap(
    (...args) =>
      import.meta.env.MODE !== 'production' &&
      // eslint-disable-next-line no-console
      console.error(...args),
  ),
)
