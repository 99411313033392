import { atom, useAtom, useAtomValue } from 'jotai'

const selectedRestaurantHash = atom<string>('')

export const RESTAURANT_HASH_STORAGE_KEY = `fat-hash.${window.location.hostname}`

const persistedSelectedRestaurantHashAtom = atom(
  get => get(selectedRestaurantHash),
  (get, set, newHash: string) => {
    set(selectedRestaurantHash, newHash)
    localStorage.setItem(RESTAURANT_HASH_STORAGE_KEY, newHash)
  },
)

export const useSelectedRestaurantHashAtom = () =>
  useAtom(persistedSelectedRestaurantHashAtom)

export const useRestaurantHash = () =>
  useAtomValue(persistedSelectedRestaurantHashAtom)
