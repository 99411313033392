import type React from 'react'
import { Box, Popover, Stack, type PopoverProps } from '@mui/material'

import { ChevronRightIcon } from 'src/components/atoms/Chevron'
import IconButton from 'src/components/atoms/IconButton'
import SizeableMenuItem from 'src/components/atoms/SizeableMenuItem'

export const subMenuVariantProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  hideBackdrop: true,
}

const SubMenu = ({
  anchorEl,
  onOpen,
  open,
  size,
  label,
  children,
}: {
  open: boolean
  onOpen: () => void
  anchorEl: HTMLElement | null
  size: 'small' | 'medium'
  label: React.ReactNode
  children: React.ReactNode
}) => (
  <>
    <Box>
      <SizeableMenuItem
        disableGutters
        value={null}
        size={size}
        onClick={onOpen}
        sx={{
          pr: 0,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
        >
          {label}
          <IconButton size="small" iconSize="medium" color="neutral">
            <ChevronRightIcon />
          </IconButton>
        </Stack>
      </SizeableMenuItem>
    </Box>
    <Popover
      open={open}
      anchorEl={anchorEl}
      sx={{
        pointerEvents: 'none',
      }}
      {...subMenuVariantProps}
    >
      <Box
        sx={{
          pointerEvents: 'all',
        }}
      >
        {children}
      </Box>
    </Popover>
  </>
)

export default SubMenu
