import { QueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'

import { HttpErrorCodes } from 'src/types/api'

const NO_RETRY_ERROR_CODES = [HttpErrorCodes.NotFound]

export const queryClientFactory = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchInterval: 30 * 1000,
        refetchOnReconnect: 'always',
        staleTime: 30 * 1000,
        refetchIntervalInBackground: false,
        retry: (retryCount, error) => {
          if (isAxiosError(error)) {
            if (NO_RETRY_ERROR_CODES.includes(error.status as HttpErrorCodes))
              return false
          }

          return retryCount < 3
        },
      },
    },
  })

const queryClient = queryClientFactory()

export default queryClient
