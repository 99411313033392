import { type ComponentProps } from 'react'
import { Box, Divider, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import image from './foratable-logo-white.png'

const FooterLink = ({
  sx,
  ...rest
}: Omit<ComponentProps<typeof Typography<'a'>>, 'component'>) => (
  <Typography
    component="a"
    variant="body3"
    sx={{
      color: 'inherit',
      fontWeight: 'inherit',
      textDecoration: 'none',
      transition: theme =>
        theme.transitions.create(['color'], {
          duration: theme.transitions.duration.short,
          easing: theme.transitions.easing.easeInOut,
        }),
      ':hover': {
        color: 'white',
      },
      ...sx,
    }}
    {...rest}
  />
)

export const openPrivacySettingsModal = () => {
  try {
    window.UC_UI?.showSecondLayer()
  } catch {
    // eslint-disable-next-line no-console
    console.log('UC_UI is not defined')
  }
}

const Footer = ({
  loginUrl,
  logoLink = import.meta.env.PUBLIC_FAT_URL,
}: {
  loginUrl?: string
  logoLink?: string
}) => {
  const { t } = useTranslation()

  return (
    <Box
      component="footer"
      sx={{
        background: '#402f43',
        color: '#fbfcfe',
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          px: 2,
          py: 2,
          mx: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            gap: 4,
          }}
        >
          <Box>
            <Box component="a" href={logoLink} target="_blank" rel="noopener">
              <Box component="img" src={image} sx={{ maxWidth: '150px' }} />
            </Box>
            <Typography
              component="p"
              variant="body3"
              fontWeight="bold"
              sx={{ mt: 4 }}
            >
              Lunchgate AG
            </Typography>
            <Typography component="p" variant="body3">
              Badenerstrasse 255
            </Typography>
            <Typography component="p" variant="body3">
              CH-8003 Zürich
            </Typography>
            <Typography component="p" variant="body3">
              +41 43 501 00 50
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography component="p" variant="body3" fontWeight="bold">
              {t('common.email', {
                defaultValue: 'E-mail',
                tDescription: 'E-mail label',
              })}
              :{' '}
              <FooterLink
                href="mailto:office@lunchgate.com"
                sx={{
                  color: '#ee7033',
                  fontWeight: 'normal',
                }}
              >
                office@lunchgate.com
              </FooterLink>
            </Typography>
            <Typography component="p" variant="body3" fontWeight="bold">
              {t('common.phone', {
                defaultValue: 'Phone',
                tDescription: 'Short phone number label',
              })}
              :{' '}
              <FooterLink
                href="tel:+41435010050"
                variant="body3"
                sx={{
                  color: '#ee7033',
                  fontWeight: 'normal',
                }}
              >
                +41 43 501 00 50
              </FooterLink>
            </Typography>
          </Box>
        </Box>
        <Divider
          sx={{
            my: 4,
            borderColor: '#fbfcfe',
          }}
        />
        {loginUrl && (
          <FooterLink
            href={loginUrl}
            sx={{
              display: 'block',
              my: 4,
              color: 'inherit',
              fontWeight: 'bold',
            }}
          >
            Login
          </FooterLink>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            gap: 4,
            color: '#bec0c5',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              gap: [1, 2],
              pb: 2,
            }}
          >
            <FooterLink
              href="https://go.foratable.com/datenschutzerklarung"
              target="_blank"
              rel="noopener"
            >
              {t('general.privacy_policy', {
                defaultValue: 'Privacy policy',
                tDescription: 'Privacy policy',
              })}
            </FooterLink>
            <FooterLink
              href="https://go.foratable.com/impressum"
              target="_blank"
              rel="noopener"
            >
              {t('general.impressum', {
                defaultValue: 'Impressum',
                tDescription: 'Footer impressum link text',
              })}
            </FooterLink>
            <FooterLink href="#" onClick={openPrivacySettingsModal}>
              {t('general.privacy_configuration', {
                defaultValue: 'Privacy Settings',
                tDescription: 'Privacy Settings link text',
              })}
            </FooterLink>
          </Box>
          <Typography component="p" variant="body3">
            {t('general.all_rights_reserved', {
              defaultValue: '© {{date}} Lunchgate. All rights reserved',
              tDescription: 'All rights reserved text',
              date: new Date().getFullYear(),
            })}
            .
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
