const primary = {
  25: '#F8F9FC',
  50: '#F2F3FA',
  100: '#E4E8F6',
  200: '#C6CEEE',
  300: '#A3B1E5',
  400: '#768FDC',
  500: '#2661D3',
  600: '#2156BC',
  700: '#1D4BA3',
  800: '#183D85',
  900: '#102B5E',
}

export default primary
