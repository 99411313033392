// eslint-disable-next-line max-classes-per-file
import type { AxiosError, AxiosRequestConfig } from 'axios'
import { z } from 'zod'

import type { WithRequired } from 'src/types/common'

export class MissingTokenError extends Error {}

export class TokenRefreshError extends Error {}

export enum HttpErrorCodes {
  AuthenticationError = 401,
  BadRequest = 400,
  NotFound = 404,
}

export class ApiError extends Error {
  constructor(
    message: string,
    public longMessage: string,
    public errorNumber: number,
    public statusCode: HttpErrorCodes,
    public state = 'error',
  ) {
    super(message)
  }
}

export const isApiError = <T>(error: T | Error): error is ApiError =>
  error instanceof ApiError

export const handleApiError =
  (handler?: (error: ApiError) => void) => (error: Error) => {
    if (!isApiError(error)) throw error

    handler?.(error)

    return error
  }

export interface BadRequestError extends ApiError {
  statusCode: HttpErrorCodes.BadRequest
}

export const isBadRequestError = (error: Error): error is BadRequestError =>
  isApiError(error) && error.statusCode === HttpErrorCodes.BadRequest

export interface AuthenticationError extends ApiError {
  statusCode: HttpErrorCodes.AuthenticationError
}

export const isAuthenticationError = (
  error: Error,
): error is AuthenticationError =>
  isApiError(error) && error.statusCode === HttpErrorCodes.AuthenticationError

interface ApiErrorData extends Error {
  error_number: number
  message: string
  long_message: string
}

export type ApiErrorResponse = WithRequired<
  AxiosError<ApiErrorData>,
  'response'
>
export const isApiErrorResponse = (
  error: AxiosError,
): error is ApiErrorResponse => !!error.response?.data

export type ApiRequest = WithRequired<AxiosRequestConfig<object>, 'url'>
export type ApiResponse = Promise<unknown>

const pageSchema = z.object({
  after: z.string().nullable(),
  before: z.string().nullable(),
  size: z.number(),
})

export type Page = z.infer<typeof pageSchema>

const paginatedResponseSchema = z.object({
  page: pageSchema,
  meta: z.object({
    page: z.object({
      total: z.number(),
    }),
  }),
})

export const createResponseSchema = <U, T extends z.ZodType<U>>(
  dataSchema: T,
) =>
  z.object({
    data: dataSchema,
  })

export const createPaginatedResponseSchema = <U, T extends z.ZodType<U>>(
  dataSchema: T,
) => createResponseSchema(dataSchema).and(paginatedResponseSchema)

export const assertMeaningfulError = (error: ApiError) => {
  if (error.statusCode !== HttpErrorCodes.BadRequest || !error.message) {
    throw error
  }
}
