import { find } from 'ramda'

import { hasIdRelationBy } from 'src/service/functional'
import { areInsideRestaurant } from 'src/service/restaurant'
import { type RestaurantInterface } from 'src/types/restaurant'
import { type RoomInterface } from 'src/types/room'

export const areInsideRoom = hasIdRelationBy('roomId')

areInsideRoom([{ roomId: '' }])({ id: 1 })

export const getRoomRestaurant =
  (restaurants: RestaurantInterface[]) => (room: RoomInterface) =>
    find(areInsideRestaurant([room]), restaurants)
