import {
  complement,
  compose,
  curry,
  identity,
  invoker,
  useWith as ramdaUseWith,
} from 'ramda'

import dayjs, { type ManipulateType, type OpUnitType } from 'dayjs'

import 'dayjs/locale/de'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/fr'
import 'dayjs/locale/hr'
import 'dayjs/locale/it'

import duration, { type DurationUnitType } from 'dayjs/plugin/duration'
import isoWeek from 'dayjs/plugin/isoWeek'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timeZone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { roundToNumber } from 'src/service/math'
import type { IsoDuration } from 'src/service/zod'
import { type DefinedRangeInterface } from 'src/types/range'
import { languageToLocale } from './i18n'

dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.locale('de')
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timeZone)
dayjs.extend(isoWeek)

export default dayjs

export type IsoDateString = string

export const fromDatetimeString = (datetime: string) =>
  dayjs(datetime, [
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD HH:mm',
    'DD.MM.YY HH:mm',
  ]).toDate()

export const fromDateString = (date: string) =>
  dayjs(date, ['YYYY-MM-DD', 'DD.MM.YY']).toDate()

export const fromDateAndTime = (date?: string, time?: string) =>
  !date || !time ? null : fromDatetimeString(`${date} ${time}`)

export const fromApiString = (date: string | undefined) =>
  !date ? null : dayjs(date, ['YYYY-MM-DD', 'MM-DD']).toDate()

/** @deprecated use date.toISOString whenever supported by API */
export const toApiDateString = (date: Date) => dayjs(date).format('YYYY-MM-DD')

export const formatDatetime = (date: Date) => dayjs(date).format('LLLL')

export const formatDate = (date: Date) => dayjs(date).format('LL')

export const shortDateFormatter = (
  language: string,
  options = {
    day: 'numeric',
    weekday: 'short',
    month: 'short',
    year: 'numeric',
    hour: undefined,
    minute: undefined,
    second: undefined,
    timeZoneName: undefined,
  } as Intl.DateTimeFormatOptions,
) => new Intl.DateTimeFormat(languageToLocale(language), options)

export const formatShortDatetime = (date: Date) => dayjs(date).format('llll')

export const formatShortDate = (date: Date) => dayjs(date).format('l')

export const isBirthday = (birthDate: Date | null, date: Date = new Date()) => {
  if (!birthDate) return false

  const dayJsDate = dayjs(date)
  const birthdate = dayjs(birthDate)

  return birthdate.set('year', dayJsDate.get('year')).isSame(dayJsDate, 'day')
}

export const fromTime = (time: string) => dayjs(time, 'HH:mm').toDate()

export const formatTime = (date: Date) => dayjs(date).format('HH:mm')

export const areOnSame = (unit: OpUnitType) => (d1: Date, d2: Date) =>
  dayjs(d1).isSame(d2, unit)

export const areOnSameDay = areOnSame('day')

export const addToDate: (
  number: number,
  unit: ManipulateType,
  date: Date | null,
) => Date = compose(
  invoker(0, 'toDate'),
  ramdaUseWith(invoker(2, 'add'), [identity, identity, dayjs]),
)

export const combineDateWithTime = (
  dateSource: Date,
  timeSource: Date,
): Date => {
  const dayjsTime = dayjs(timeSource)

  return dayjs(dateSource)
    .set('hour', dayjsTime.get('hour'))
    .set('minute', dayjsTime.get('minute'))
    .set('second', dayjsTime.get('second'))
    .set('millisecond', dayjsTime.get('millisecond'))
    .toDate()
}

export const isInFuture =
  (timeUnit?: OpUnitType) => (referenceDate: Date, testDate: Date) =>
    dayjs(referenceDate).isBefore(dayjs(testDate), timeUnit)

export const isInPast =
  (timeUnit?: OpUnitType) => (referenceDate: Date, testDate: Date) =>
    dayjs(referenceDate).isAfter(dayjs(testDate), timeUnit)

export const getMinutes = (date: Date) => dayjs(date).minute()

export const getHours = (date: Date) => dayjs(date).hour()

export const fromMilliseconds = (unit: DurationUnitType) => (millis: number) =>
  dayjs.duration(millis).as(unit)
export const toMilliseconds = (unit: DurationUnitType) => (value: number) =>
  dayjs.duration(value, unit).asMilliseconds()

export const toTimestamp = (date: Date): number => date?.getTime?.()
export const fromTimestamp = (timestamp: number): Date => new Date(timestamp)

const compareDates = (dateA: Date, dateB: Date): number =>
  toTimestamp(dateA) - toTimestamp(dateB)

export const areDatesEqual = complement(compareDates)
export const isDateSame = curry(areDatesEqual)

export const getStartOf =
  (unit: OpUnitType) =>
  (date: Date | null): Date =>
    dayjs(date).startOf(unit).toDate()

export const getEndOf =
  (unit: OpUnitType) =>
  (date: Date): Date =>
    dayjs(date).endOf(unit).toDate()

export const getBoundaries = (
  date = new Date(),
  unit: ManipulateType = 'day',
): DefinedRangeInterface<Date> => {
  const dayjsDate = dayjs(date)

  return [dayjsDate.startOf(unit).toDate(), dayjsDate.endOf(unit).toDate()]
}

const convertBetweenTimezones = (from?: string, to?: string) => (date: Date) =>
  dayjs.tz(date, from).utc(true).tz(to, true).toDate()

export const convertToTimezone = (
  date: Date,
  timezone = 'Europe/Zurich',
  fromTimezone?: string,
): Date => convertBetweenTimezones(fromTimezone, timezone)(date)

export const convertFromTimezone = (date: Date, timezone = 'Europe/Zurich') =>
  convertBetweenTimezones(timezone, undefined)(date)

export const getUtcDayStart = (date: Date): Date =>
  dayjs(date).startOf('day').utc(true).toDate()

export const roundToNearest = (
  unit: DurationUnitType,
  target = 1,
  roundMethod = Math.round,
) => {
  const durationMs = toMilliseconds(unit)(target)
  return compose(
    fromTimestamp,
    roundToNumber(durationMs, roundMethod),
    toTimestamp,
  )
}

export const toDate = (dateLike: ConstructorParameters<typeof Date>[number]) =>
  new Date(dateLike)

export const getDifference =
  (unit: DurationUnitType) => (dateA: Date, dateB: Date) =>
    Math.abs(dayjs(dateA).diff(dayjs(dateB), unit))

export const getRemainingTimeString = (to: Date, from = new Date()) =>
  dayjs(from).to(to, true)

export const formatDuration = (isoDuration: IsoDuration) =>
  dayjs.duration(isoDuration).humanize()

export const weekdayFormatter = (locale = 'de-CH') =>
  new Intl.DateTimeFormat(locale, {
    weekday: 'long',
  })

export const getDayOfWeek = (date: Date) => dayjs(date).isoWeekday()

export const getWeekdayOccurrenceInMonth = (
  weekdayDate: Date,
  searchFromEnd = false,
) => {
  const dayOfMonth = weekdayDate.getDate()

  if (!searchFromEnd) return Math.ceil(dayOfMonth / 7)

  const daysInMonth = dayjs(weekdayDate).daysInMonth()

  return -Math.ceil((daysInMonth + 1 - dayOfMonth) / 7)
}

export const weekdayNumbers = Array.from({ length: 7 }, (_, i) => i + 1)

export const getWeekdaysNumbers = () => weekdayNumbers

export const getWeekdayName = (weekday: number) => {
  const now = dayjs()

  return now.isoWeekday(weekday).format('dd')
}

export const fromIsoDuration = (
  isoDuration: IsoDuration,
  unit: DurationUnitType,
) => dayjs.duration(isoDuration).as(unit)

export const toIsoDuration = (amount: number, unit: DurationUnitType) =>
  dayjs.duration(amount, unit).toISOString() as IsoDuration
