import { z } from 'zod'

import { dateSchema } from 'src/service/zod'
import { createPaginatedResponseSchema } from 'src/types/api'

const reservationSearchDataSchema = z.array(z.number())

export const reservationPaginatedSearchResponseSchema =
  createPaginatedResponseSchema(reservationSearchDataSchema)

export const reservationLinkSchema = z.object({
  url: z.string().url(),
  name: z.string(),
})

export type ReservationLink = z.infer<typeof reservationLinkSchema>

export const emptyLink: ReservationLink = {
  name: '',
  url: '',
}

const paymentSchema = z.object({
  paymentPlanId: z.string(),
  amountPerPerson: z.number(),
  currency: z.string(),
  expirationDate: dateSchema.nullable(),
  status: z.enum(['provided', 'expired', 'pending', 'charged']),
})

export type ReservationPayment = z.infer<typeof paymentSchema>

export const reservationUpdateSchema = z.object({
  id: z.number().gt(0),
  firstName: z.coerce.string(),
  lastName: z.coerce.string(),
  companyName: z.coerce.string(),
  date: z.string().datetime({ offset: true }),
  seats: z.number(),
  duration: z.number(),
  labels: z.array(z.coerce.number()),
  room: z.number().nullable(),
  customer: z.number().nullable(),
  restaurantNote: z.string(),
  guestNote: z.string(),
  restaurantHash: z.string(),
  links: z.array(reservationLinkSchema),
  payment: paymentSchema.nullable(),
})

export type ApiReservationUpdate = z.infer<typeof reservationUpdateSchema>

export const reservationStatusChangeSchema = z.object({
  id: z.number().gt(0),
})

export type ApiReservationStatusChange = z.infer<
  typeof reservationStatusChangeSchema
>

export const reservationCreateSchema = z.object({
  firstName: z.coerce.string(),
  lastName: z.coerce.string(),
  companyName: z.coerce.string(),
  customer: z.number().nullable(),
  date: dateSchema,
  duration: z.number(),
  room: z.coerce.number(),
})

export type ApiReservationCreate = z.infer<typeof reservationCreateSchema>

export const reservationCreatedResponseSchema = z
  .object({
    reservation_id: z.number(),
    resnumber: z.number(),
    reservations: z.array(
      z.object({ reservation_id: z.number() }).passthrough(),
    ),
  })
  .transform(d => ({
    id: d.reservation_id,
    no: d.resnumber,
    angularReservation: d.reservations.find(
      r => r.reservation_id === d.reservation_id,
    ),
  }))

export type ApiReservationCreateResponse = z.infer<
  typeof reservationCreatedResponseSchema
>
