import type React from 'react'

import { AblyProvider, ChannelProvider } from 'ably/react'

import { useAblyChannelName } from 'src/hooks/useSseEventListenerEffect'
import ablyClient from 'src/service/ablyClient'

export const withSseClientProvider =
  <T extends object>(
    Component: React.FunctionComponent<T>,
  ): React.FunctionComponent<T> =>
  props => (
    <AblyProvider client={ablyClient}>
      <ChannelProvider channelName={useAblyChannelName()}>
        <Component {...props} />
      </ChannelProvider>
    </AblyProvider>
  )
